import React from "react";
import { useQuery } from "react-query";
import SectionHeading from "../SectionHeading/SectionHeading";
import Post from "./Post";

const PostWrapper = () => {
  const getAllArticles = async () => {
    try {
      // Temporary sample data while setting up MDX
      const sampleArticles = [
        {
          articleId: "getting-started",
          title: "Getting Started with Lab Work",
          description:
            "Essential tips for beginning your journey in clinical laboratory work",
          image: "/images/blog1.jpg",
          date: "2024-03-21",
          author: "Sarah Johnson",
        },
        {
          articleId: "lab-safety",
          title: "Laboratory Safety Guidelines",
          description:
            "Important safety protocols every lab technician should know",
          image: "/images/blog2.jpg",
          date: "2024-03-20",
          author: "John Smith",
        },
        {
          articleId: "career-tips",
          title: "Career Development in Clinical Labs",
          description: "How to advance your career in laboratory science",
          image: "/images/blog3.jpg",
          date: "2024-03-19",
          author: "Emily Brown",
        },
      ];

      // Comment out the MDX loading code for now
      /* const articles = await Promise.all(
        require
          .context("../../content/articles", false, /\.mdx$/)
          .keys()
          .map(async (filename) => {
            const article = await import(`../../content/articles/${filename}`);
            return {
              articleId: filename.replace(/^\.\/(.*)\.mdx$/, "$1"),
              ...article.metadata,
            };
          })
      ); */

      return sampleArticles;
    } catch (error) {
      console.error("Error loading articles:", error);
      return [];
    }
  };

  const { data: posts = [] } = useQuery("posts", getAllArticles);

  return (
    <section id="blog">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading
        title="Latest News"
        subTitle="Whether you're new to the field or a seasoned professional, our blog keeps you informed on the latest trends,<br /> lab hacks, and career tips in the dynamic world of clinical laboratories."
      />
      <div className="container">
        <div className="row">
          {posts.slice(0, 3).map((post, index) => (
            <Post key={post.articleId || index} {...post} />
          ))}
        </div>
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default PostWrapper;
