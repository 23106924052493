import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Preloader from "../Preloader/Preloader";

const headerData = {
  logo: "/images/logo.png",
};

const footerData = {
  logo: "/images/footer-logo.png",
  bgImg: "/images/footer-bg.png",
  subTitle: "We're obsessed with helping you launch your clinical lab career!",
};

const Layout = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="st-content-wrap">
          <Header data={headerData} />
          <main className="st-main-content">
            <Outlet />
          </main>
          <Footer data={footerData} />
        </div>
      )}
    </>
  );
};

export default Layout;
